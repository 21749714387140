import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const GuestbookPage = () => (
  <Layout>
    <Seo title="Gæstebog" />
    <div className="w-full bg-background h-max p-5 shadow-md">
      <div className="bg-white rounded-lg">
        <div className="bg-black text-white p-2 rounded-t-lg">Gæstebog</div>
        <div className="p-2">
            <button className="bg-yellow-800 text-white p-1 rounded-lg shadow-sm font-bold">Skriv i min gæstebog</button>
        </div>

        <div className="p-2">
          <div className="pl-2 border-t border-l border-r border-black">
              01-01-2022 23:00 <a className="text-red-800 font-bold" href="#">EmilStahl</a>
          </div>
          <div className="border  h-24 p-3 border-black">
              Hej min ven
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default GuestbookPage
